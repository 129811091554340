<template>
    <div class="CardRenew" style="background-color: #f2f2f2; margin-top: 8px">
        <el-card shadow="never" style="margin-top: 8px">
            <el-form
                @submit.native.prevent
                :inline="true"
                style="text-align: right"
                v-model="form"
                size="small"
                label-width="85px"
            >
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="机构组名称">
                            <el-input v-model="meta.deptGroupName" readonly />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="机构名称">
                            <el-select v-model="card.deptCode">
                                <el-option value="" label="请选择" />
                                <el-option
                                    v-for="dept in meta.deptCodes"
                                    :label="dept.name"
                                    :key="dept.code"
                                    :value="dept.code"
                                />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="创建人">
                            <el-input readonly v-model="card.creator" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="创建时间">
                            <el-date-picker v-model="card.createDate" type="date" value-format="yyyy-MM-dd" readonly />
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </el-card>
        <el-card shadow="never" style="margin-top: 8px; text-align: left">
            <el-button type="primary" @click="save" size="small">保存</el-button>
        </el-card>
        <el-card shadow="never" style="margin-top: 8px">
            <el-table
                id="printMe"
                border
                stripe
                style="width: 100%"
                :data="tableData"
                size="mini"
                :highlight-current-row="true"
                max-height="520"
            >
                <el-table-column label="全选" type="selection" width="60" align="center" fixed="left" />
                <el-table-column label="序号" width="60" type="index" fixed="left" />
                <el-table-column label="会员卡号" width="240" prop="cardPrintNo" />
                <el-table-column label="卡状态" width="120" prop="status">
                    <template slot-scope="scope"
                        ><span>{{ scope.row.status | status }}</span></template
                    >
                </el-table-column>
                <el-table-column label="发卡机构" width="120" prop="deptName" />
                <el-table-column label="会员名称" width="120" prop="memberName" />
                <el-table-column label="手机号" width="160" prop="mobile" />
                <el-table-column label="储蓄余额" width="160" prop="createTime">
                    <template slot-scope="scope">
                        <span>{{ fmtTwo(scope.row.money) }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="积分" width="160" prop="point" />
            </el-table>
        </el-card>
    </div>
</template>
<script>
import UrlUtils from '../../../../js/UrlUtils';

export default {
    name: 'CardRenew',
    props: ['form'],
    data() {
        return {
            tableData: [],
            card: {
                name: this.form.name,
                mobile: this.form.mobile,
                sex: this.form.sex,
                age: this.form.age,
                provinceCode: this.form.provinceCode,
                cityCode: this.form.cityCode,
                districtCode: this.form.districtCode,
                address: this.form.address,
                deptCode: '',
                createDate: new Date(),
                creator: this.$store.state.session.name,
            },
            meta: {
                groups: [],
                deptCodes: [],
                deptGroupName: '',
                deptGroupCode: '',
            },
            loadingDeptGroupFlag: true,
            url: {
                queryGroups: '/system/deptGroup/list',
                queryDeptByGroup: '/system/dept/deptTree',
                queryDetail: '/member/card/info_c/',
                save: '/member/card/recoverCard',
            },
        };
    },
    filters: {
        status(status) {
            if (typeof status == 'undefined') {
                return '';
            }
            if (status == '1') {
                return '正常';
            }
            if (status == '2') {
                return '丢失';
            }
            if (status == '3') {
                return '作废';
            }
        },
    },
    mounted() {
        this.handleQueryDetail();
    },
    methods: {
        handleChangeGroup() {
            const _this = this;
            UrlUtils.QueryRemote(
                this,
                this.url.queryDeptByGroup + '?deptGroupCode=' + _this.meta.deptGroupCode,
                (rst) => {
                    _this.meta.deptCodes = rst;
                }
            );
        },
        handleQueryDetail() {
            const _this = this;
            UrlUtils.QueryRemote(this, this.url.queryDetail + _this.form.code, (rst) => {
                _this.meta.deptGroupCode = rst.deptGroupCode || '';
                _this.meta.deptGroupName = rst.deptGroupName || '';
                _this.tableData.push(rst);
                _this.handleChangeGroup();
            });
        },

        save() {
            const _this = this;
            if (!_this.card.deptCode) {
                _this.$message.error('请选择机构');
                return;
            }
            const _params = {
                code: _this.form.code,
                cardPrintNo: _this.form.cardPrintNo,
                deptCode: _this.card.deptCode,
            };
            UrlUtils.PatchRemote(_this, _this.url.save, _params, null, () => {
                _this.goBackAndReload();
            });
        },
    },
};
</script>

<style scoped>
.el-input {
    width: auto;
}
</style>
